function Footer() {
  return (
    <footer className={"footer"}>
      <div className="px-4 py-[100px] bg-center">
        <div className="container text-center">
          <p className="text-[16px] opacity-95 font-normal" style={{maxWidth: '88%', margin: '0 auto'}}>
            Disclaimer: The information provided shall not in any way constitute a recommendation as to whether you should invest in any product discussed. $PINKMOON accepts no liability for any loss occasioned to any person acting or refraining from action as a result of any material provided or published. © 2021 by $PINKMOON team!
          </p>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
