import SocialSharing from '../../components/SocialSharing'
import PoolCard from '../../components/PoolCard'

import AppConfig from '../../configs'
import { useEffect, useState } from 'react'
function abbreviateNumber(num: any) {
  if (num >= 10000000000000) {
    return (num / 1000000000000).toFixed(0).replace(/\.0$/, '') + 'T'
  }
  if (num >= 1000000000) {
    return (num / 1000000000).toFixed(1).replace(/\.0$/, '') + 'G'
  }
  if (num >= 1000000) {
    return (num / 1000000).toFixed(1).replace(/\.0$/, '') + 'M'
  }
  if (num >= 1000) {
    return (num / 1000).toFixed(1).replace(/\.0$/, '') + 'K'
  }
  return num
}
function HomePage() {
  const [state, setState] = useState<any>()
  useEffect(() => {
    const promise = []
    promise.push(
      fetch(
        'https://9mu8umn2d1.execute-api.ap-northeast-1.amazonaws.com/dev/getPrice',
      ),
    )
    promise.push(
      fetch(
        'https://9mu8umn2d1.execute-api.ap-northeast-1.amazonaws.com/dev/getCirculatingSupply',
      ),
    )
    promise.push(
      fetch(
        'https://9mu8umn2d1.execute-api.ap-northeast-1.amazonaws.com/dev/getBNBPrice',
      ),
    )
    Promise.all(promise).then((res) => {
      const promiseJson = []
      promiseJson.push(res[0].json())
      promiseJson.push(res[1].json())
      promiseJson.push(res[2].json())
      Promise.all(promiseJson).then((res1) => {
        const { tokenPriceVsQuote } = res1[0]
        const circulatingSupply = res1[1]
        const bnbPrice = res1[2]
        const mCap = abbreviateNumber(
          tokenPriceVsQuote * circulatingSupply * bnbPrice,
        )
        const burn = abbreviateNumber(1000000000000000 - circulatingSupply)
        setState({
          mCap,
          burn,
        })
      })
    })
  }, [])
  return (
    <>
      <div className="px-4 py-[120px] bg-pinkmoon-img bg-center">
        <div className="container text-center bg-text">
          <h1 className="text-[60px] mb-[60px] intro-title">
            PinkMoon Protocol
          </h1>
          <p className="text-[24px] font-normal mb-[30px]">
            The next frontier in community driven value - Auto locking LP to
            enable contract driven passive rewards. Simply hold the token to
            join the journey and reap the rewards.
          </p>
          <p className="text-[24px] font-normal mb-[30px]">
            Token Address: 0xb6090a50f66046E3c6aFB9311846a6432E45060A
          </p>

          <div className="intro-button">
            <a href="https://exchange.pancakeswap.finance/#/swap?inputCurrency=0xb6090a50f66046E3c6aFB9311846a6432E45060A">
              Pancake V2 (15% Slippage)
            </a>
          </div>

          <SocialSharing />

          <div className="intro-columns clearfix">
            <div className="intro-column">
              <h3>51K</h3>

              <p>Holders</p>
            </div>

            <div className="intro-column">
              <h3>${state?.mCap || '--'}</h3>

              <p>Market Cap</p>
            </div>

            <div className="intro-column">
              <h3>140K</h3>

              <p>Transactions</p>
            </div>

            <div className="intro-column">
              <h3>${state?.burn || '--'}</h3>

              <p>Burned</p>
            </div>
          </div>
        </div>
      </div>
      <div className="bg-white">
        <div className="px-4 py-[60px]">
          <div className="container">
            <h2 className="pools-title">Upcoming Pools</h2>
          </div>

          <div className="mt-4 flex items-stretch flex-wrap justify-center" style={{flexDirection:'column',alignItems:'center'}}>
            {AppConfig.pool.enabled.map((pool) => (
              <div key={pool.address} className="w-[100%] h-full lg:w-[25%]">
                <PoolCard {...pool} />
              </div>
            ))}
          </div>
        </div>
      </div>

      <div className="custom-main pl-[20px] pr-[20px]">
        <div className="container">
          <div className="features clearfix">
            <div className="feature">
              <img src="feature-1.png" alt="hold" />

              <h2>Hold and Earn</h2>

              <p>
                Every transaction incrues this fee of 10% to the benefit of all,
                of which: 5% are distributed among all diamond hands holders. 5%
                are locked away in the liquidity pool, to create a steadily
                rising price floor.
              </p>

              <p>
                Anti whale trades, transactions (sell/buy) that trade more than
                0.05% of the total supply will be rejected.
              </p>
            </div>

            <div className="feature">
              <img src="feature-2.png" alt="hold" />

              <h2>Liquidity Locked</h2>

              <p>
                The Liquidity of PinkMoon (45% of total supply) will be locked
                at launch with Unicrypt.
              </p>

              <p>
                The contract owner has been renounced, leaving the contract
                trustless for the community.
              </p>
            </div>

            <div className="feature">
              <img src="feature-3.png" alt="hold" />

              <h2>100% Community Driven</h2>

              <p>
                Community driven & fair launch. Dev burned all team tokens and
                participated in the fair launch with everyone else.
              </p>

              <p>
                PinkMoon holders will receive more and more power to steer the
                project. Holding to get allocations and vote for your favorite
                idos as well.
              </p>
            </div>
          </div>

          {/*<div className="tier-allocations">*/}
          {/*   <h3>Tier Allocations</h3>*/}
          {/*  <div className="allocations clearfix">*/}
          {/*    <div className="allocation">*/}
          {/*      <div className="allocation-box">*/}
          {/*        <h3>Tier 1</h3>*/}

          {/*        <span className={"guaranteed"}>Guaranteed</span>*/}

          {/*        <img src={"feature-3.png"} alt={"tier-1"}/>*/}

          {/*        <span className={"holding-avr"}>Holding Avr 3 Days</span>*/}

          {/*        <span className={"holding-number"}>1,000,000,000,000 $PinkM</span>*/}

          {/*        <span className={"pool-weight"}>Pool Weight</span>*/}

          {/*        <span className={"pool-number"}>60%</span>*/}
          {/*      </div>*/}
          {/*    </div>*/}

          {/*    <div className="allocation">*/}
          {/*      <div className="allocation-box">*/}
          {/*        <h3>Tier 1</h3>*/}

          {/*        <span className={"guaranteed"}>Guaranteed</span>*/}

          {/*        <img src={"feature-3.png"} alt={"tier-1"}/>*/}

          {/*        <span className={"holding-avr"}>Holding Avr 3 Days</span>*/}

          {/*        <span className={"holding-number"}>1,000,000,000,000 $PinkM</span>*/}

          {/*        <span className={"pool-weight"}>Pool Weight</span>*/}

          {/*        <span className={"pool-number"}>60%</span>*/}
          {/*      </div>*/}
          {/*    </div>*/}

          {/*    <div className="allocation">*/}
          {/*      <div className="allocation-box">*/}
          {/*        <h3>Tier 1</h3>*/}

          {/*        <span className={"guaranteed"}>Guaranteed</span>*/}

          {/*        <img src={"feature-3.png"} alt={"tier-1"}/>*/}

          {/*        <span className={"holding-avr"}>Holding Avr 3 Days</span>*/}

          {/*        <span className={"holding-number"}>1,000,000,000,000 $PinkM</span>*/}

          {/*        <span className={"pool-weight"}>Pool Weight</span>*/}

          {/*        <span className={"pool-number"}>60%</span>*/}
          {/*      </div>*/}
          {/*    </div>*/}
          {/*  </div>*/}
          {/*</div>*/}

          {/*<div className="whitelist cleafix">*/}
          {/*  <div className="whitelist-button">*/}
          {/*    <a*/}
          {/*      target="_blank"*/}
          {/*      rel="noreferrer noopenner"*/}
          {/*      href="https://docs.google.com/forms/d/e/1FAIpQLScztUAj7xpdWkTraIYGs_zHP9o5NPu_l5b1rfI54w6084C6PQ/viewform"*/}
          {/*    >*/}
          {/*      Join Whitelist*/}
          {/*    </a>*/}
          {/*  </div>*/}
          {/*</div>*/}
        </div>
      </div>
    </>
  )
}

export default HomePage
