import { HashRouter as Router, Route, Switch } from 'react-router-dom';
import MainLayout from './layouts/main/MainLayout';
import AirdropPage from './pages/Airdrop/AirdropPage';

import HomePage from './pages/Home/HomePage';
import PoolDetailPage from './pages/PoolDetail/PoolDetailPage';
import Tiers from './pages/Tiers/Tiers';
import getRoute, { RouteNames } from './utils/path';

function App() {
  return (
    <MainLayout>
      <Router>
        <Switch>
          <Route exact path={getRoute(RouteNames.PoolDetail)} component={PoolDetailPage} />
          <Route exact path={getRoute(RouteNames.Airdrop)} component={AirdropPage} />
          <Route exact path={getRoute(RouteNames.Tiers)} component={Tiers} />
          <Route path={getRoute(RouteNames.Home)} component={HomePage} />

        </Switch>
      </Router>
    </MainLayout>
  );
}

export default App;
