/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
import { ChangeEvent, FC, useCallback, useEffect, useMemo, useState } from "react";
import Countdown, { zeroPad } from 'react-countdown';
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import Web3 from 'web3';

import useAccountInfo from "../hooks/useAccountInfo";
import usePoolContract from "../hooks/usePoolContract";
import usePoolInfo from "../hooks/usePoolInfo";
import useTokenInfo from "../hooks/useTokenInfo";
import useWeb3 from "../hooks/useWeb3";
import useWeb3Modal from "../hooks/useWeb3Modal";
import IPool from "../types/IPool";
import { fromWei, numberFormat } from "../utils/helper";
import getRoute, { RouteNames } from "../utils/path";

import Button from "./Button";

// @ts-ignore
const BN = Web3.utils.BN;

const rendererCountdown = ({ days, hours, minutes, seconds }: any) => (
  <div className="text-[13px]">
    <span className="px-2 py-1 inline-block bg-pinkmoon text-[#212121] rounded font-semibold">{zeroPad(days)}</span>
    <span className="mx-1">:</span>
    <span className="px-2 py-1 inline-block bg-pinkmoon text-[#212121] rounded font-semibold">{zeroPad(hours)}</span>
    <span className="mx-1">:</span>
    <span className="px-2 py-1 inline-block bg-pinkmoon text-[#212121] rounded font-semibold">{zeroPad(minutes)}</span>
    <span className="mx-1">:</span>
    <span className="px-2 py-1 inline-block bg-pinkmoon text-[#212121] rounded font-semibold">{zeroPad(seconds)}</span>
  </div>
);

interface Props extends IPool {
  hideName?: boolean;
}

const PoolCard: FC<Props> = ({
  name,
  abi,
  address,
  icon,
  accessType,
  maximumBnbToBuy,
  tokenSymbol,
  hideName = false,
  id,
  version,
}) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [amount, setAmount] = useState<string>();
  const [status, setStatus] = useState<'nothing' | 'incoming' | 'running' | 'ended'>('nothing');
  const info = usePoolInfo(address, abi, version);
  const contract = usePoolContract(address, abi);
  const account = useAccountInfo();
  const {
    totalBnbToRaise,
    totalParticipants,
    totalBnbRaised,
    totalTokenToSell,
    startDate,
    endDate,
    withdrawnAmount,
    purchasedAmount,
    isUserWhitelisted,
  } = info;
  const { token } = useTokenInfo(info.token);
  const accountInfo = useAccountInfo();
  const { createTransaction, sendTransaction } = useWeb3Modal();
  const web3Instance = useWeb3();
  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    setAmount(e.target.value);
  };
  const tokenPrice = useMemo(() => {
    if (totalBnbToRaise === '0' || !token?.decimals) return 0;
    try {
      if (version === 2 ) {
        console.log(Web3.utils.fromWei(info?.rate||'0'))
        return fromWei(info.rate || '0', token?.decimals);
      }
      if (version === 3) {
        return Web3.utils.fromWei(info?.rate||'0')
      }
      return new BN(Web3.utils.fromWei(totalTokenToSell || '0', 'gwei')).div(new BN(Web3.utils.fromWei(totalBnbToRaise || '0'))).toString();
    } catch (e) {
      return '-';
    }
  }, [totalTokenToSell, totalBnbToRaise, info.rate, token?.decimals]);
  useEffect(() => {
    const now = Math.floor(+new Date() / 1000);
    const start = Number(startDate);
    const end = Number(endDate);
    if (!start || !end) return;
    if (now < start) {
      setStatus('incoming');
      return;
    }
    if (now >= start && now <= end) {
      setStatus('running');
      return;
    };
    setStatus('ended');
  }, [startDate, endDate]);
  const symbol = useMemo(() => {
    if (!token || !token.symbol) return tokenSymbol;
    return token.symbol;
  }, [token]);
  const percent = useMemo(() => {
    try {
      if (totalBnbToRaise === '0') return '0';
      return (Number(Web3.utils.fromWei(totalBnbRaised || '0')) / Number(Web3.utils.fromWei(totalBnbToRaise || '0'))) * 100;
    } catch (e) {
      return '0';
    }
  }, [totalBnbRaised, totalBnbToRaise]);
  const percent1 = useMemo(() => {
    if (version === 1) return '0';
    try {
      if (info.hardcapOfTier1 === '0') return '0';
      return (Number(Web3.utils.fromWei(info.totalPurchaseOfTier1 || '0')) / Number(Web3.utils.fromWei(info.hardcapOfTier1 || '0'))) * 100;
    } catch (e) {
      return '0';
    }
  }, [info.totalPurchaseOfTier1, info.hardcapOfTier1]);
  const percent2 = useMemo(() => {
    if (version === 1) return '0';
    try {
      if (info.hardcapOfTier2 === '0') return '0';
      return (Number(Web3.utils.fromWei(info.totalPurchaseOfTier2 || '0')) / Number(Web3.utils.fromWei(info.hardcapOfTier2 || '0'))) * 100;
    } catch (e) {
      return '0';
    }
  }, [info.totalPurchaseOfTier2, info.hardcapOfTier2]);
  const percent3 = useMemo(() => {
    if (version === 1) return '0';
    try {
      if (info.hardcapOfTier3 === '0') return '0';
      return (Number(Web3.utils.fromWei(info.totalPurchaseOfTier3 || '0')) / Number(Web3.utils.fromWei(info.hardcapOfTier3 || '0'))) * 100;
    } catch (e) {
      return '0';
    }
  }, [info.totalPurchaseOfTier3, info.hardcapOfTier3]);
  const percent4 = useMemo(() => {
    if (version === 1) return '0';
    try {
      if (info.hardcapOfTier4 === '0') return '0';
      return (Number(Web3.utils.fromWei(info.totalPurchaseOfTier4 || '0')) / Number(Web3.utils.fromWei(info.hardcapOfTier4 || '0'))) * 100;
    } catch (e) {
      return '0';
    }
  }, [info.totalPurchaseOfTier4, info.hardcapOfTier4]);
  const percent5 = useMemo(() => {
    if (version === 1) return '0';
    try {
      if (info.hardcapOfTier5 === '0') return '0';
      return (Number(Web3.utils.fromWei(info.totalPurchaseOfTier5 || '0')) / Number(Web3.utils.fromWei(info.hardcapOfTier5 || '0'))) * 100;
    } catch (e) {
      return '0';
    }
  }, [info.totalPurchaseOfTier5, info.hardcapOfTier5]);
  const percent6 = useMemo(() => {
    if (version === 1) return '0';
    try {
      if (info.hardcapOfTier6 === '0') return '0';
      return (Number(Web3.utils.fromWei(info.totalPurchaseOfTier6 || '0')) / Number(Web3.utils.fromWei(info.hardcapOfTier6 || '0'))) * 100;
    } catch (e) {
      return '0';
    }
  }, [info.totalPurchaseOfTier6, info.hardcapOfTier6]);
  const disabled = accountInfo.balance === '0' || status !== 'running' || loading || !isUserWhitelisted;
  const purchase = useCallback(() => {
    if (!contract || !amount || !account.address) return;
    (async () => {
      setLoading(true);
      try {
        await contract.methods.purchase().send({
          from: account.address,
          value: Web3.utils.toWei(amount)
        });
        toast.success('Purchase successfully!');
      } catch (e) {
        console.log(e)
      } finally {
        setLoading(false);
      }
    })();
  }, [contract, amount, account.address]);
  const maxContributionByTier = useMemo(() => {
    if (!info.tierOf || info.tierOf === '0') return '0';
    if (info.tierOf === '1') return info.maxAllocationOfTier1;
    if (info.tierOf === '2') return info.maxAllocationOfTier2;
    if (info.tierOf === '3') return info.maxAllocationOfTier3;
    if (info.tierOf === '4') return info.maxAllocationOfTier4;
    if (info.tierOf === '5') return info.maxAllocationOfTier5;
    if (info.tierOf === '6') return info.maxAllocationOfTier6;
    return '0';
  }, [
    info.maxAllocationOfTier1,
    info.maxAllocationOfTier2,
    info.maxAllocationOfTier3,
    info.maxAllocationOfTier4,
    info.maxAllocationOfTier5,
    info.maxAllocationOfTier6,
    info.tierOf,
  ]);
  const minContributionByTier = useMemo(() => {
    if (!info.tierOf || info.tierOf === '0') return '0';
    if (info.tierOf === '1') return info.minAllocationOfTier1;
    if (info.tierOf === '2') return info.minAllocationOfTier2;
    if (info.tierOf === '3') return info.minAllocationOfTier3;
    if (info.tierOf === '4') return info.minAllocationOfTier4;
    if (info.tierOf === '5') return info.minAllocationOfTier5;
    if (info.tierOf === '6') return info.minAllocationOfTier6;
    return '0';
  }, [
    info.minAllocationOfTier1,
    info.minAllocationOfTier2,
    info.minAllocationOfTier3,
    info.minAllocationOfTier4,
    info.minAllocationOfTier5,
    info.minAllocationOfTier6,
    info.tierOf,
  ]);
  const hardcapByTier = useMemo(() => {
    if (!info.tierOf || info.tierOf === '0') return '0';
    if (info.tierOf === '1') return info.hardcapOfTier1;
    if (info.tierOf === '2') return info.hardcapOfTier2;
    if (info.tierOf === '3') return info.hardcapOfTier3;
    if (info.tierOf === '4') return info.hardcapOfTier4;
    if (info.tierOf === '5') return info.hardcapOfTier5;
    if (info.tierOf === '6') return info.hardcapOfTier6;
    return '0';
  }, [
    info.hardcapOfTier1,
    info.hardcapOfTier2,
    info.hardcapOfTier3,
    info.hardcapOfTier4,
    info.hardcapOfTier5,
    info.hardcapOfTier6,
    info.tierOf,
  ]);
  const totalPurchaseByTier = useMemo(() => {
    if (!info.tierOf || info.tierOf === '0') return '0';
    if (info.tierOf === '1') return info.totalPurchaseOfTier1;
    if (info.tierOf === '2') return info.totalPurchaseOfTier2;
    if (info.tierOf === '3') return info.totalPurchaseOfTier3;
    if (info.tierOf === '4') return info.totalPurchaseOfTier4;
    if (info.tierOf === '5') return info.totalPurchaseOfTier5;
    if (info.tierOf === '6') return info.totalPurchaseOfTier6;
    return '0';
  }, [
    info.totalPurchaseOfTier1,
    info.totalPurchaseOfTier2,
    info.totalPurchaseOfTier3,
    info.totalPurchaseOfTier4,
    info.totalPurchaseOfTier5,
    info.totalPurchaseOfTier6,
    info.tierOf,
  ]);
  const isHardCapReached = useMemo(() => {
    if (hardcapByTier === '0' || totalPurchaseByTier === '0') return false;
    const hcap = Number(
      Web3.utils.fromWei(hardcapByTier || '0'),
    );
    const total = Number(
      Web3.utils.fromWei(totalPurchaseByTier || '0'),
    );
    return total >= hcap;
  }, [hardcapByTier, totalPurchaseByTier]);
  const v2MaxContribution = useMemo(() => {
    if (version === 1) return 0;
    if (maxContributionByTier === '0' || !accountInfo.balance) return 0;
    const total = Number(
      Web3.utils.fromWei(totalPurchaseByTier || '0')
    );
    const hcap = Number(
      Web3.utils.fromWei(hardcapByTier || '0')
    );
    if (total >= hcap) return 0;
    try {
      const allc = Number(
        Web3.utils.fromWei(info.allocationFor || '0')
      );
      return allc;
      // return Math.min(allc, Number(
      //   Web3.utils.fromWei(accountInfo.balance)
      // ));
      // return Number(
      //   Web3.utils.fromWei(
      //     (
      //       new BN(maxContributionByTier).sub(
      //         new BN(info.purchaseOf)
      //       )
      //     ).toString()
      //   )
      // );
    } catch (e) {
      return 0;
    }
  }, [
    maxContributionByTier,
    info.purchaseOf,
    info.allocationFor,
    accountInfo.balance,
    hardcapByTier,
    totalPurchaseByTier,
  ]);
  const purchaseable = useMemo(() => {
    if (!amount || !minContributionByTier || !v2MaxContribution) return false;
    if (accountInfo.balance === '0' || Number(accountInfo.balance) < v2MaxContribution) return false;
    const min = Number(
      Web3.utils.fromWei(
        minContributionByTier || '0'
      )
    );
    const max = Number(
      Web3.utils.fromWei(
        maxContributionByTier || '0'
      )
    );
    const purchased = Number(
      Web3.utils.fromWei(info.purchaseOf || '0')
    );
    return Number(amount) + purchased >= min && Number(amount) + purchased <= max && status === 'running';
  }, [
    minContributionByTier,
    maxContributionByTier,
    v2MaxContribution,
    amount,
    status,
    accountInfo.balance,
    info.purchaseOf,
  ]);
  const getTxReceipt = async (hash: string) => {
    try {
      const result = await web3Instance.eth.getTransactionReceipt(
        hash
      );
      if (result) {
        toast.success('Buy successfully!');
        setLoading(false);
      } else {
        setTimeout(() => {
          getTxReceipt(hash);
        }, 2000)
      }
    } catch (e) {
      console.log(e);
    }
  };
  const handleSubmit = () => {
    if (disabled || !accountInfo.address) return;
    setLoading(true);
    const tx = createTransaction(accountInfo.address, address, '0x3782DACE9D90000');
    sendTransaction(tx)?.then((hash) => {
      getTxReceipt(hash as string);
    })
      .catch((err) => {
        toast.error(err?.message || err.toString())
        setLoading(false);
      });
  };
  const max = useCallback(() => {
    if (!v2MaxContribution) return;
    const bl = Number(
      Web3.utils.fromWei(accountInfo.balance || '0')
    );
    if (bl < v2MaxContribution) {
      setAmount(`${bl}`);
    } else {
      setAmount(`${v2MaxContribution}`);
    }
  }, [setAmount, v2MaxContribution, accountInfo.balance]);
  const title = useMemo(() => {
    if (status === 'ended') return 'This pool has been ended';
    if (accountInfo.balance === '0' || Number(accountInfo.balance) < Number(maximumBnbToBuy)) return 'Insufficient BNB balance';
    if (loading) return 'Buying...';
    if (!isUserWhitelisted) return 'User is not whitelisted';
    return `Buy ${symbol}`;
  }, [accountInfo.balance, isUserWhitelisted, loading, maximumBnbToBuy, symbol, status]);
  const v2PurchaseTitle = useMemo(() => {
    if (status === 'ended') return 'This pool has been ended';
    if (isHardCapReached) return 'Hardcap reached';
    const min = Number(
      Web3.utils.fromWei(
        minContributionByTier || '0'
      )
    );
    if (accountInfo.balance === '0' || Number(accountInfo.balance) < Number(min)) return 'Insufficient BNB balance';
    if (loading) return 'Buying...';
    if (!isUserWhitelisted) return 'User is not whitelisted';
    return `Buy ${symbol}`;
  }, [accountInfo.balance, isUserWhitelisted, loading, v2MaxContribution, symbol, status, minContributionByTier, isHardCapReached]);
  const isClosed = status === 'ended' || withdrawnAmount !== '0' || Number(percent) >= 100;
  return (
    <div className="p-2">
      <div className="border-2 border-pinkmoon-bold rounded-lg p-[20px] block hover:border-pinkmoon bg-white">
        <div className="flex mb-2">
          <div className="flex-1 flex items-center">
            {!hideName && (
              <>
                <a
                  href={"#" + getRoute(RouteNames.PoolDetail).replace(':id', id)}
                  rel="nofollow noreferrer"
                  className="line-clamp-2 underline text-[18px] font-semibold block"
                >
                  <img
                    src={icon}
                    alt={name}
                    className="w-[40px] h-[40px] mr-[10px]"
                  />
                </a>
                <a
                  href={"#" + getRoute(RouteNames.PoolDetail).replace(':id', id)}
                  rel="nofollow noreferrer"
                  className="line-clamp-2 underline text-[18px] font-semibold block"
                >
                  {name}
                </a>
              </>
            )}
          </div>
          {status !== 'nothing' && (
            <div className="flex items-center text-[12px]">
              {status === 'ended' && (
                <div className="w-[10px] h-[10px] bg-gray-500 rounded-full mr-1" />
              )}
              {status === 'incoming' && (
                <div className="w-[10px] h-[10px] bg-green-200 rounded-full mr-1" />
              )}
              {status === 'running' && (
                <div className="w-[10px] h-[10px] bg-pinkmoon-bold rounded-full mr-1" />
              )}
              <div>{status}</div>
            </div>
          )}
        </div>
        {status === 'running' && (
          <div className="text-center mt-3">
            <Countdown
              date={Number(endDate) * 1000}
              renderer={rendererCountdown}
              onComplete={() => setStatus('ended')}
            />
          </div>
        )}
        {status === 'incoming' && (
          <div className="text-center mt-3">
            <Countdown
              date={Number(startDate) * 1000}
              renderer={rendererCountdown}
              onComplete={() => setStatus('running')}
            />
          </div>
        )}
        {version === 1 && (
          <div className="mt-3 text-[16px]">
            1 BNB = {numberFormat(tokenPrice)} <span className="text-pinkmoon-bold">{symbol}</span>
          </div>
        )}
        {version === 1 && (
          <div className="mt-3 flex">
            <div className="text-semibold mr-3">Total Raise:</div>
            <div className="text-pinkmoon-bold">{Web3.utils.fromWei(totalBnbToRaise || '0')} BNB</div>
          </div>
        )}
        <div className="mt-3">
          {version === 1 && (
            <>
              <div className="text-semibold mb-2">Progress</div>
              <div className="bg-gray-200 h-[8px] rounded-lg relative">
                <div className="absolute h-[8px] rounded-lg top-0 left-0 bg-gradient-to-r from-pinkmoon to-pinkmoon-bold" style={{ width: `${percent}%` }} />
              </div>
              <div className="flex items-center">
                <div className="text-sm">{Web3.utils.fromWei(totalBnbRaised || '0')}</div>
                <div className="flex-1" />
                <div className="text-sm">{Web3.utils.fromWei(totalBnbToRaise || '0')} BNB</div>
              </div>
            </>
          )}
          {(version === 2 || version === 3) && (
            <>
              <div className="text-semibold mb-2">Tier 1</div>
              <div className="bg-gray-200 h-[8px] rounded-lg relative">
                <div className="absolute h-[8px] rounded-lg top-0 left-0 bg-gradient-to-r from-pinkmoon to-pinkmoon-bold" style={{ width: `${percent1}%` }} />
              </div>
              <div className="flex items-center">
                <div className="text-sm">{Web3.utils.fromWei(info.totalPurchaseOfTier1 || '0')}</div>
                <div className="flex-1" />
                <div className="text-sm">{Web3.utils.fromWei(info.hardcapOfTier1 || '0')} BNB</div>
              </div>

              <div className="text-semibold mb-2">Tier 2</div>
              <div className="bg-gray-200 h-[8px] rounded-lg relative">
                <div className="absolute h-[8px] rounded-lg top-0 left-0 bg-gradient-to-r from-pinkmoon to-pinkmoon-bold" style={{ width: `${percent2}%` }} />
              </div>
              <div className="flex items-center">
                <div className="text-sm">{Web3.utils.fromWei(info.totalPurchaseOfTier2 || '0')}</div>
                <div className="flex-1" />
                <div className="text-sm">{Web3.utils.fromWei(info.hardcapOfTier2 || '0')} BNB</div>
              </div>

              <div className="text-semibold mb-2">Tier 3</div>
              <div className="bg-gray-200 h-[8px] rounded-lg relative">
                <div className="absolute h-[8px] rounded-lg top-0 left-0 bg-gradient-to-r from-pinkmoon to-pinkmoon-bold" style={{ width: `${percent3}%` }} />
              </div>
              <div className="flex items-center">
                <div className="text-sm">{Web3.utils.fromWei(info.totalPurchaseOfTier3 || '0')}</div>
                <div className="flex-1" />
                <div className="text-sm">{Web3.utils.fromWei(info.hardcapOfTier3 || '0')} BNB</div>
              </div>

              {version === 3 && (
                <>
                  <div className="text-semibold mb-2">Tier 4</div>
                  <div className="bg-gray-200 h-[8px] rounded-lg relative">
                    <div className="absolute h-[8px] rounded-lg top-0 left-0 bg-gradient-to-r from-pinkmoon to-pinkmoon-bold" style={{ width: `${percent4}%` }} />
                  </div>
                  <div className="flex items-center">
                    <div className="text-sm">{Web3.utils.fromWei(info.totalPurchaseOfTier4 || '0')}</div>
                    <div className="flex-1" />
                    <div className="text-sm">{Web3.utils.fromWei(info.hardcapOfTier4 || '0')} BNB</div>
                  </div>

                  <div className="text-semibold mb-2">Tier 5</div>
                  <div className="bg-gray-200 h-[8px] rounded-lg relative">
                    <div className="absolute h-[8px] rounded-lg top-0 left-0 bg-gradient-to-r from-pinkmoon to-pinkmoon-bold" style={{ width: `${percent5}%` }} />
                  </div>
                  <div className="flex items-center">
                    <div className="text-sm">{Web3.utils.fromWei(info.totalPurchaseOfTier5 || '0')}</div>
                    <div className="flex-1" />
                    <div className="text-sm">{Web3.utils.fromWei(info.hardcapOfTier5 || '0')} BNB</div>
                  </div>

                  <div className="text-semibold mb-2">Tier 6</div>
                  <div className="bg-gray-200 h-[8px] rounded-lg relative">
                    <div className="absolute h-[8px] rounded-lg top-0 left-0 bg-gradient-to-r from-pinkmoon to-pinkmoon-bold" style={{ width: `${percent6}%` }} />
                  </div>
                  <div className="flex items-center">
                    <div className="text-sm">{Web3.utils.fromWei(info.totalPurchaseOfTier6 || '0')}</div>
                    <div className="flex-1" />
                    <div className="text-sm">{Web3.utils.fromWei(info.hardcapOfTier6 || '0')} BNB</div>
                  </div>
                </>
              )}
            </>
          )}
        </div>
        {/* <div className="mt-3">
          <div className="flex items-center">
            <div className="flex-1 mr-2 text-center text-sm">
              <div className="text-semibold">Min</div>
              <div className="text-pinkmoon-bold">$0</div>
            </div>
            <div className="flex-1 mr-2 text-center text-sm">
              <div className="text-semibold">Max</div>
              <div className="text-pinkmoon-bold">$500</div>
            </div>
            <div className="flex-1 mr-2 text-center text-sm">
              <div className="text-semibold">Access</div>
              <div className="text-pinkmoon-bold">private</div>
            </div>
          </div>
        </div> */}
        {/* only in feature pool */}
        <div className="mt-3 mb-4">
          <div className="flex items-center">
            <div className="flex-1 mr-2 text-center text-sm">
              <div className="text-semibold">{version === 1 ? 'Participants' : 'Whitelist'}</div>
              <div className="text-pinkmoon-bold">{version === 1 ? totalParticipants : info.numberOfWhitelisted}</div>
            </div>
            {
              version === 1 && (
                <div className="flex-1 mr-2 text-center text-sm">
                  <div className="text-semibold">BNB to buy</div>
                  <div className="text-pinkmoon-bold">{maximumBnbToBuy}</div>
                </div>
              )
            }
            {(version === 2 || version === 3) && (
              <div className="flex-1 mr-2 text-center text-sm">
                <div className="text-semibold">Rate</div>
                <div className="text-pinkmoon-bold">
                  {numberFormat(tokenPrice)} <span>{symbol}</span>/BNB
                </div>
              </div>
            )}
            <div className="flex-1 mr-2 text-center text-sm">
              <div className="text-semibold">Access</div>
              <div className="text-pinkmoon-bold">{accessType}</div>
            </div>
          </div>
        </div>
        {withdrawnAmount !== '0' && version === 1 && (
          <div className="flex text-[12px]">
            <div className="text-semibold flex-1">Your Withdrawn</div>
            <div className="text-pinkmoon-bold">{numberFormat(Web3.utils.fromWei(withdrawnAmount || '0', 'gwei'))} {symbol}</div>
          </div>
        )}
        {purchasedAmount !== '0' && version === 1 && (
          <div className="flex text-[12px]">
            <div className="text-semibold flex-1">Your Purchased</div>
            <div className="text-pinkmoon-bold">{numberFormat(Web3.utils.fromWei(purchasedAmount || '0', 'gwei'))} {symbol}</div>
          </div>
        )}
        {(version === 2 || version === 3) && (
          <>
            <div className="flex text-[12px]">
              <div className="text-semibold flex-1">Your tier</div>
              <div className="text-pinkmoon-bold">{info && info.tierOf && info.tierOf !== '0' ? `TIER ${info.tierOf}` : '-'}</div>
            </div>
            <div className="flex text-[12px]">
              <div className="text-semibold flex-1">Your purchased</div>
              <div className="text-pinkmoon-bold">{numberFormat(Web3.utils.fromWei(info.purchaseOf || '0'))} BNB</div>
            </div>
            <div className="flex text-[12px]">
              <div className="text-semibold flex-1">Min buy by tier</div>
              <div className="text-pinkmoon-bold">{Web3.utils.fromWei(minContributionByTier || '0')} BNB</div>
            </div>
            <div className="flex text-[12px]">
              <div className="text-semibold flex-1">Max buy by tier</div>
              <div className="text-pinkmoon-bold">{Web3.utils.fromWei(maxContributionByTier || '0')} BNB</div>
            </div>
            <div className="flex text-[12px]">
              <div className="text-semibold flex-1">Available buy</div>
              <div className="text-pinkmoon-bold">{v2MaxContribution} BNB</div>
            </div>
          </>
        )}
        {hideName && (version === 2 || version === 3) && (
          <div className="mt-3">
            <div className="flex items-center">
              <input
                type="number"
                className="flex-2 mr-2 px-2 py-2 text-pinkmoon-bold border-pinkmoon-bold border w-full rounded-md focus:border-pinkmoon-bold"
                placeholder="Enter the number of BNB to buy"
                onChange={handleChange}
                value={amount}
              />
              <Button title="Max" onClick={max} />
            </div>
            <div className="flex mt-3">
              <Button title={v2PurchaseTitle} onClick={purchase} disabled={loading || !purchaseable || isHardCapReached} />
            </div>
          </div>
        )}
        {hideName && (
          <div>
            {version === 1 && (
              <div className="mt-3 flex">
                {isClosed ? (
                  <Button
                    title="Pool closed"
                    disabled
                  />
                ) : (
                  <>
                    {purchasedAmount !== '0' ? (
                      <Button
                        title="Already purchased"
                        disabled
                      />
                    ) : (
                      <Button
                        title={status === 'incoming' ? 'The poll will open soon' : title}
                        disabled={disabled}
                        onClick={handleSubmit}
                      />
                    )}
                  </>
                )}
              </div>
            )}
          </div>
        )}
        {!hideName && (
          <div className="mt-3">
            <Link
              to={getRoute(RouteNames.PoolDetail).replace(':id', id)}
              className="text-center block text-[#212121] bg-white border-2 py-2 px-4 rounded-lg font-semibold hover:bg-pinkmoon hover:border-pinkmoon duration-200"
            >
              View Pool
            </Link>
          </div>
        )}
      </div>
    </div>
  );
};

export default PoolCard;
