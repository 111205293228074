import { FC } from "react";

type Props = {
  title: string;
  disabled?: boolean;
  onClick?(): void;
};

const Button: FC<Props> = ({ title, disabled = false, onClick }) => {
  return (
    <button
      type="button"
      onClick={onClick}
      disabled={disabled}
      className="flex-1 duration-200 outline-none bg-pinkmoon px-4 py-2 inline-block rounded-lg text-[#212121] font-semibold cursor-pointer hover:bg-pinkmoon-bold disabled:cursor-not-allowed disabled:bg-gray-300"
    >
      {title}
    </button>
  );
};

export default Button;
