import { FC } from "react";
import AirdropButton from "../../components/AirdropButton";

const AirdropPage: FC = () => {
  return (
      <div className="px-4 py-[64px]" style={{background: '#f9f9f9'}}>
          <div className={"container"}>
            <div className="mx-auto m-8 mb-[64px]" style={{maxWidth: '640px'}}>
              <AirdropButton />
            </div>
          </div>
      </div>
  );
};

export default AirdropPage;
