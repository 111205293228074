import ConnectButton from "../../components/ConnectButton";

function Header() {
  return (
    <div className="bg-white pl-[20px] pr-[20px]">
      <div className="container">
        <header className="pt-[20px] pb-[20px] clearfix">
          <div className="logo">
            <div className="logo-icon">
              <a href=".">
                <img src="/pinkmoon.png" alt="PinkMoon" className="w-[70px] h-[70px]" />
              </a>
            </div>

            <div className="logo-text">
              <a href=".">
                <div className="ml-4 font-bold">PinkMoon</div>
              </a>
            </div>
          </div>

          <div className="navigation-button text-right">
            <div className="navigation">
              <ul>
                <li><a href="https://www.pinkmoon.finance/">Homepage</a></li>
                <li><a href="/#/tiers">Tiers</a></li>
                <li><a href="/#/airdrop">Airdrop</a></li>
                <li><a href="https://shop.pinkmoon.finance/">Merch</a></li>
              </ul>
            </div>

            <ConnectButton />
          </div>
        </header>
      </div>
    </div>
  );
}

export default Header;
