import { FaTelegram, FaTwitter, FaMedium, FaGithub } from 'react-icons/fa';

function SocialSharing() {
  return (
    <div className="flex items-center justify-center mt-[20px] mb-[100px]">
      <a href="https://t.me/pinkmoonfinance" className="duration-200 mr-8 text-[28px]" target="_blank" rel="nofollow noreferrer">
        <FaTelegram />
      </a>
      <a href="https://twitter.com/pinkmoonfinance" className="duration-200 mr-8 text-[28px]" target="_blank" rel="nofollow noreferrer">
        <FaTwitter />
      </a>
      <a href="https://medium.com/@pinkmoonfinance" className="duration-200 mr-8 text-[28px]" target="_blank" rel="nofollow noreferrer">
        <FaMedium />
      </a>
      <a href="https://github.com/pinkmoonfinance" className="duration-200 mr-8 text-[28px]" target="_blank" rel="nofollow noreferrer">
        <FaGithub />
      </a>
    </div>
  );
}

export default SocialSharing;
