/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useRef, useState } from "react";
import { useMount } from "react-use";
import useWeb3 from "./useWeb3";

interface IPoolInfo {
  totalTokenToSell?: string;
  totalTokenToSold?: string;
  totalBnbToRaise?: string;
  totalBnbRaised?: string;
  purchasedAmount?: string;
  withdrawnAmount?: string;
  isUserWhitelisted?: boolean;
  isUserParticipated?: boolean;
  startDate?: string;
  endDate?: string;
  totalParticipants?: string;
  // v2 & v3 (tier 123)
  allocationFor?: string;
  numberOfWhitelisted?: string;
  owner?: string;
  purchaseOf?: string;
  rate?: string;
  token?: string;
  volumeOf?: string;
  withdrawn?: string;
  maxAllocationOfTier1?: string;
  maxAllocationOfTier2?: string;
  maxAllocationOfTier3?: string;
  minAllocationOfTier1?: string;
  minAllocationOfTier2?: string;
  minAllocationOfTier3?: string;
  tierOf?: string;
  totalPurchaseOfTier1?: string;
  totalPurchaseOfTier2?: string;
  totalPurchaseOfTier3?: string;
  hardcapOfTier1?: string;
  hardcapOfTier2?: string;
  hardcapOfTier3?: string;
  // v3
  maxAllocationOfTier4?: string;
  maxAllocationOfTier5?: string;
  maxAllocationOfTier6?: string;
  minAllocationOfTier4?: string;
  minAllocationOfTier5?: string;
  minAllocationOfTier6?: string;
  totalPurchaseOfTier4?: string;
  totalPurchaseOfTier5?: string;
  totalPurchaseOfTier6?: string;
  hardcapOfTier4?: string;
  hardcapOfTier5?: string;
  hardcapOfTier6?: string;
}

interface IReturn extends IPoolInfo {
  loading: boolean;
}

function usePoolInfo(address: string, abi: string, version = 1): IReturn {
  const [info, setInfo] = useState<IPoolInfo>({
    totalTokenToSell: "0",
    totalTokenToSold: "0",
    totalBnbToRaise: "0",
    totalBnbRaised: "0",
    purchasedAmount: "0",
    withdrawnAmount: "0",
    isUserWhitelisted: false,
    isUserParticipated: false,
    startDate: "0",
    endDate: "0",
    totalParticipants: "0",
  });
  const [loading, setLoading] = useState<boolean>(false);
  const contractRef = useRef();
  const addressRef = useRef<string>();
  const web3 = useWeb3();
  const getInfo = async () => {
    if (!contractRef.current || !addressRef.current) return;
    setLoading(true);
    try {
      if (version === 1) {
        const [
          totalTokenToSell,
          totalTokenToSold,
          totalBnbToRaise,
          totalBnbRaised,
          purchasedAmount,
          withdrawnAmount,
          isUserWhitelisted,
          isUserParticipated,
          totalParticipants,
          startDate,
          endDate,
        ] = await Promise.all([
          // @ts-ignore
          contractRef.current?.methods.totalTokensToSell().call(),
          // @ts-ignore
          contractRef.current?.methods.totalTokensSold().call(),
          // @ts-ignore
          contractRef.current?.methods.totalBnbToRaise().call(),
          // @ts-ignore
          contractRef.current?.methods.totalBNBRaised().call(),
          // @ts-ignore
          contractRef.current?.methods
            .purchasedAmount(addressRef.current)
            .call(),
          // @ts-ignore
          contractRef.current?.methods
            .withdrawnAmount(addressRef.current)
            .call(),
          // @ts-ignore
          contractRef.current?.methods
            .isUserWhitelisted(addressRef.current)
            .call(),
          // @ts-ignore
          contractRef.current?.methods
            .isUserParticipated(addressRef.current)
            .call(),
          // @ts-ignore
          contractRef.current?.methods.totalParticipants().call(),
          // @ts-ignore
          contractRef.current?.methods.startDate().call(),
          // @ts-ignore
          contractRef.current?.methods.endDate().call(),
        ]);
        setInfo({
          totalTokenToSell,
          totalTokenToSold,
          totalBnbToRaise,
          totalBnbRaised,
          purchasedAmount,
          withdrawnAmount,
          isUserWhitelisted,
          isUserParticipated,
          startDate,
          endDate,
          totalParticipants,
        });
      } else if (version === 2) {
        const [
          allocationFor,
          endDate,
          numberOfWhitelisted,
          isUserWhitelisted,
          owner,
          purchaseOf,
          rate,
          startDate,
          token,
          volumeOf,
          withdrawn,
          maxAllocationOfTier1,
          maxAllocationOfTier2,
          maxAllocationOfTier3,
          minAllocationOfTier1,
          minAllocationOfTier2,
          minAllocationOfTier3,
          tierOf,
          totalPurchaseOfTier1,
          totalPurchaseOfTier2,
          totalPurchaseOfTier3,
          hardcapOfTier1,
          hardcapOfTier2,
          hardcapOfTier3,
        ] = await Promise.all([
          // @ts-ignore
          contractRef.current?.methods.allocationFor(addressRef.current).call(),
          // @ts-ignore
          contractRef.current?.methods.endDate().call(),
          // @ts-ignore
          contractRef.current?.methods.getNumberOfWhitelisted().call(),
          // @ts-ignore
          contractRef.current?.methods
            .isUserWhitelisted(addressRef.current)
            .call(),
          // @ts-ignore
          contractRef.current?.methods.owner().call(),
          // @ts-ignore
          contractRef.current?.methods.purchaseOf(addressRef.current).call(),
          // @ts-ignore
          contractRef.current?.methods.rate().call(),
          // @ts-ignore
          contractRef.current?.methods.startDate().call(),
          // @ts-ignore
          contractRef.current?.methods.token().call(),
          // @ts-ignore
          contractRef.current?.methods.volumeOf(addressRef.current).call(),
          // @ts-ignore
          contractRef.current?.methods.withdrawn(addressRef.current).call(),
          // @ts-ignore
          contractRef.current?.methods.maxAllocationOfTier(1).call(),
          // @ts-ignore
          contractRef.current?.methods.maxAllocationOfTier(2).call(),
          // @ts-ignore
          contractRef.current?.methods.maxAllocationOfTier(3).call(),
          // @ts-ignore
          contractRef.current?.methods.minAllocationOfTier(1).call(),
          // @ts-ignore
          contractRef.current?.methods.minAllocationOfTier(2).call(),
          // @ts-ignore
          contractRef.current?.methods.minAllocationOfTier(3).call(),
          // @ts-ignore
          contractRef.current?.methods.tierOf(addressRef.current).call(),
          
          // @ts-ignore
          contractRef.current?.methods.totalPurchaseOfTier(1).call(),
          // @ts-ignore
          contractRef.current?.methods.totalPurchaseOfTier(2).call(),
          // @ts-ignore
          contractRef.current?.methods.totalPurchaseOfTier(3).call(),

          // @ts-ignore
          contractRef.current?.methods.hardcapOfTier(1).call(),
          // @ts-ignore
          contractRef.current?.methods.hardcapOfTier(2).call(),
          // @ts-ignore
          contractRef.current?.methods.hardcapOfTier(3).call(),
        ]);
        setInfo({
          allocationFor,
          endDate,
          numberOfWhitelisted,
          isUserWhitelisted: isUserWhitelisted['0'],
          owner,
          purchaseOf,
          rate,
          startDate,
          token,
          volumeOf,
          withdrawn,
          maxAllocationOfTier1,
          maxAllocationOfTier2,
          maxAllocationOfTier3,
          minAllocationOfTier1,
          minAllocationOfTier2,
          minAllocationOfTier3,
          tierOf,
          totalPurchaseOfTier1,
          totalPurchaseOfTier2,
          totalPurchaseOfTier3,
          hardcapOfTier1,
          hardcapOfTier2,
          hardcapOfTier3,
        });
      } else {
        const [
          allocationFor,
          endDate,
          numberOfWhitelisted,
          isUserWhitelisted,
          owner,
          purchaseOf,
          rate,
          startDate,
          token,
          volumeOf,
          withdrawn,
          maxAllocationOfTier1,
          maxAllocationOfTier2,
          maxAllocationOfTier3,
          minAllocationOfTier1,
          minAllocationOfTier2,
          minAllocationOfTier3,
          maxAllocationOfTier4,
          maxAllocationOfTier5,
          maxAllocationOfTier6,
          minAllocationOfTier4,
          minAllocationOfTier5,
          minAllocationOfTier6,
          tierOf,
          totalPurchaseOfTier1,
          totalPurchaseOfTier2,
          totalPurchaseOfTier3,
          hardcapOfTier1,
          hardcapOfTier2,
          hardcapOfTier3,
          totalPurchaseOfTier4,
          totalPurchaseOfTier5,
          totalPurchaseOfTier6,
          hardcapOfTier4,
          hardcapOfTier5,
          hardcapOfTier6,
        ] = await Promise.all([
          // @ts-ignore
          contractRef.current?.methods.allocationFor(addressRef.current).call(),
          // @ts-ignore
          contractRef.current?.methods.endDate().call(),
          // @ts-ignore
          contractRef.current?.methods.getNumberOfWhitelisted().call(),
          // @ts-ignore
          contractRef.current?.methods
            .isUserWhitelisted(addressRef.current)
            .call(),
          // @ts-ignore
          contractRef.current?.methods.owner().call(),
          // @ts-ignore
          contractRef.current?.methods.purchaseOf(addressRef.current).call(),
          // @ts-ignore
          contractRef.current?.methods.rate().call(),
          // @ts-ignore
          contractRef.current?.methods.startDate().call(),
          // @ts-ignore
          contractRef.current?.methods.token().call(),
          // @ts-ignore
          contractRef.current?.methods.volumeOf(addressRef.current).call(),
          // @ts-ignore
          contractRef.current?.methods.withdrawn(addressRef.current).call(),
          // @ts-ignore
          contractRef.current?.methods.maxAllocationOfTier(1).call(),
          // @ts-ignore
          contractRef.current?.methods.maxAllocationOfTier(2).call(),
          // @ts-ignore
          contractRef.current?.methods.maxAllocationOfTier(3).call(),
          // @ts-ignore
          contractRef.current?.methods.minAllocationOfTier(1).call(),
          // @ts-ignore
          contractRef.current?.methods.minAllocationOfTier(2).call(),
          // @ts-ignore
          contractRef.current?.methods.minAllocationOfTier(3).call(),
          // @ts-ignore
          contractRef.current?.methods.maxAllocationOfTier(4).call(),
          // @ts-ignore
          contractRef.current?.methods.maxAllocationOfTier(5).call(),
          // @ts-ignore
          contractRef.current?.methods.maxAllocationOfTier(6).call(),
          // @ts-ignore
          contractRef.current?.methods.minAllocationOfTier(4).call(),
          // @ts-ignore
          contractRef.current?.methods.minAllocationOfTier(5).call(),
          // @ts-ignore
          contractRef.current?.methods.minAllocationOfTier(6).call(),
          // @ts-ignore
          contractRef.current?.methods.tierOf(addressRef.current).call(),
          
          // @ts-ignore
          contractRef.current?.methods.totalPurchaseOfTier(1).call(),
          // @ts-ignore
          contractRef.current?.methods.totalPurchaseOfTier(2).call(),
          // @ts-ignore
          contractRef.current?.methods.totalPurchaseOfTier(3).call(),

          // @ts-ignore
          contractRef.current?.methods.hardcapOfTier(1).call(),
          // @ts-ignore
          contractRef.current?.methods.hardcapOfTier(2).call(),
          // @ts-ignore
          contractRef.current?.methods.hardcapOfTier(3).call(),

          // @ts-ignore
          contractRef.current?.methods.totalPurchaseOfTier(4).call(),
          // @ts-ignore
          contractRef.current?.methods.totalPurchaseOfTier(5).call(),
          // @ts-ignore
          contractRef.current?.methods.totalPurchaseOfTier(6).call(),

          // @ts-ignore
          contractRef.current?.methods.hardcapOfTier(4).call(),
          // @ts-ignore
          contractRef.current?.methods.hardcapOfTier(5).call(),
          // @ts-ignore
          contractRef.current?.methods.hardcapOfTier(6).call(),
        ]);
        setInfo({
          allocationFor,
          endDate,
          numberOfWhitelisted,
          isUserWhitelisted: isUserWhitelisted['0'],
          owner,
          purchaseOf,
          rate,
          startDate,
          token,
          volumeOf,
          withdrawn,
          maxAllocationOfTier1,
          maxAllocationOfTier2,
          maxAllocationOfTier3,
          minAllocationOfTier1,
          minAllocationOfTier2,
          minAllocationOfTier3,
          maxAllocationOfTier4,
          maxAllocationOfTier5,
          maxAllocationOfTier6,
          minAllocationOfTier4,
          minAllocationOfTier5,
          minAllocationOfTier6,
          tierOf,
          totalPurchaseOfTier1,
          totalPurchaseOfTier2,
          totalPurchaseOfTier3,
          hardcapOfTier1,
          hardcapOfTier2,
          hardcapOfTier3,
          totalPurchaseOfTier4,
          totalPurchaseOfTier5,
          totalPurchaseOfTier6,
          hardcapOfTier4,
          hardcapOfTier5,
          hardcapOfTier6,
        });
        // console.log({
        //   allocationFor,
        //   endDate,
        //   numberOfWhitelisted,
        //   isUserWhitelisted: isUserWhitelisted['0'],
        //   owner,
        //   purchaseOf,
        //   rate,
        //   startDate,
        //   token,
        //   volumeOf,
        //   withdrawn,
        //   maxAllocationOfTier1,
        //   maxAllocationOfTier2,
        //   maxAllocationOfTier3,
        //   minAllocationOfTier1,
        //   minAllocationOfTier2,
        //   minAllocationOfTier3,
        //   maxAllocationOfTier4,
        //   maxAllocationOfTier5,
        //   maxAllocationOfTier6,
        //   minAllocationOfTier4,
        //   minAllocationOfTier5,
        //   minAllocationOfTier6,
        //   tierOf,
        //   totalPurchaseOfTier1,
        //   totalPurchaseOfTier2,
        //   totalPurchaseOfTier3,
        //   hardcapOfTier1,
        //   hardcapOfTier2,
        //   hardcapOfTier3,
        //   totalPurchaseOfTier4,
        //   totalPurchaseOfTier5,
        //   totalPurchaseOfTier6,
        //   hardcapOfTier4,
        //   hardcapOfTier5,
        //   hardcapOfTier6,
        // })
      }
    } catch (e) {
      // console.log(e);
    } finally {
      setLoading(false);
    }
  };

  const getInfoInterval = () => {
    getInfo();
    setTimeout(() => {
      getInfoInterval();
    }, 2000);
  };

  useEffect(() => {
    if (web3 && web3.eth) {
      (async () => {
        const contract = new web3.eth.Contract(abi, address);
        const [user] = await web3.eth.getAccounts();
        contractRef.current = contract;
        addressRef.current = user;
      })();
    }
  }, [web3, abi, address]);

  useMount(() => {
    getInfoInterval();
  });

  return {
    ...info,
    loading,
  };
}

export default usePoolInfo;
