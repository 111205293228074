import useCommon from './useCommon';

export default function useAccountInfo() {
  const [common] = useCommon();
  return {
    address: common.address,
    chainId: common.chainId,
    balance: common.balance,
  };
}
