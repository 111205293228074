export enum RouteNames {
  Home,
  PoolDetail,
  Airdrop,
  Tiers,
}

export default function getRoute(name: RouteNames) {
  return {
    [RouteNames.Home]: '/',
    [RouteNames.Tiers]: '/tiers',
    [RouteNames.PoolDetail]: '/pool/:id',
    [RouteNames.Airdrop]: '/airdrop',
  }[name]
}
