import contract from './contract';
import links from './links';
import pool from './pool';

const AppConfig = {
  contract,
  links,
  pool,
};

export default AppConfig;
