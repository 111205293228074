/* eslint-disable no-restricted-globals */
import { FC } from 'react';
import { AiOutlineLogout } from 'react-icons/ai';
import Web3 from 'web3';

import useAccountInfo from '../hooks/useAccountInfo';

import useWeb3Modal from '../hooks/useWeb3Modal';
import AppConfigs from '../configs';
import AppConfig from '../configs';
import Button from './Button';

const chainToNetwork: any = AppConfig.contract.chainToNetwork;

const ConnectButton: FC = () => {
  const accountInfo = useAccountInfo();
  const { connected, web3Connect, disconnect } = useWeb3Modal();

  const connectWalletProvider = async () => {
    try {
      await web3Connect();
    } catch (e) {
      // can not connect to provider or modal is closed.
    }
  };

  const logout = () => {
    const conf = confirm('Do you want to logout?')
    if (conf) {
      disconnect();
    }
  };

  if (accountInfo.address && accountInfo.chainId && connected) {
    return (
      <div className="inline-block text-[16px]">
        <div className="flex items-center">
          {accountInfo.chainId !== AppConfig.contract.supportedChain.BSC_MAINNET && (
            <div className="mr-4">
              {chainToNetwork[accountInfo.chainId] ?? accountInfo.chainId}
            </div>
          )}
          <div className="flex-1 bg-pinkmoon rounded-md flex items-center">
            <div className="pl-2">
              <span className="mr-1 font-semibold">{Number(Web3.utils.fromWei(accountInfo.balance || '0')).toFixed(3)}</span>
              <span>{AppConfigs.contract.TOKEN_SYMBOL}</span>
            </div>
            <div className="px-2 py-1 bg-pinkmoon-bold rounded-md ml-2 line-clamp-1">
              {`${accountInfo.address.substring(0, 5)}...${accountInfo.address.substring(accountInfo.address.length - 5)}`}
            </div>
          </div>
          <div className="px-[8px] cursor-pointer hover:text-pinkmoon-bold" onClick={logout}>
            <AiOutlineLogout />
          </div>
        </div>
      </div>
    );
  }

  return (
    <Button
      title="Connect Wallet"
      onClick={connectWalletProvider}
    />
  );
};

export default ConnectButton;
