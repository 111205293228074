import { useEffect, useState } from "react";
import useWeb3 from "./useWeb3";

const ERC20Abi = require('../assets/contracts/ERC20Abi.json');

export default function useTokenInfo(address?: string) {
  const [tokenInfo, setTokenInfo] = useState<{
    name: string;
    decimals: string;
    symbol: string;
    totalSupply: string;
  }>();
  const [loading, setLoading] = useState<boolean>(false);
  const web3 = useWeb3();

  useEffect(() => {
    if (!address || !web3) return;
    (async () => {
      try {
        setLoading(true);
        const tokenContract = new web3.eth.Contract(ERC20Abi, address);
        const [name, symbol, decimals, totalSupply] = await Promise.all([
          tokenContract.methods.name().call(),
          tokenContract.methods.symbol().call(),
          tokenContract.methods.decimals().call(),
          tokenContract.methods.totalSupply().call(),
        ]);
        setTokenInfo({
          name,
          symbol,
          decimals,
          totalSupply,
        });
      } catch (e) {
        // 
      } finally {
        setLoading(false);
      }
    })();
  }, [address, web3]);

  return {
    token: tokenInfo,
    loading,
  };
}
