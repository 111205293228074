import { FC, useCallback, useEffect, useMemo, useState } from "react";
import { toast } from "react-toastify";
import Countdown from 'react-countdown';

import AppConfig from "../configs";
import useAccountInfo from "../hooks/useAccountInfo";
import useWeb3 from "../hooks/useWeb3";

const abi = require('../assets/contracts/Airdrop.json');

const AirdropButton: FC = () => {
  const web3 = useWeb3();
  const { address } = useAccountInfo();
  const [isWhiteListed, setIsWhiteListed] = useState<boolean>(false);
  const [claimed, setClaimed] = useState<boolean>(false);
  const [claimable, setClaimable] = useState<boolean>(false);
  const [time, setTime] = useState<number>();
  const [nonce, setNonce] = useState<number>(1);
  const [allocation, setAllocation] = useState<string>();

  const onCountdownComplete = useCallback(() => {
    setClaimable(true);
    setNonce(nonce + 1);
  }, [nonce])

  useEffect(() => {
    if (!web3 || !address) return;
    (async () => {
      try {
        const contract = new web3.eth.Contract(abi, AppConfig.contract.AIRDROP_CONTRACT);
        const wl = await contract.methods.isWhitelisted(address).call();
        const allc = await contract.methods.getAllocation(address).call();
        const hasClaim = await contract.methods.claimed(address).call();
        setIsWhiteListed(wl);
        setAllocation(allc);
        setClaimed(hasClaim);
      } catch (e) {
        //
      }
    })();
  }, [web3, address, nonce]);

  useEffect(() => {
    if (!web3) return;
    (async () => {
      try {
        const contract = new web3.eth.Contract(abi, AppConfig.contract.AIRDROP_CONTRACT);
        const t = await contract.methods.startTime().call();
        setTime(t);
      } catch (e) {
        //
      }
    })();
  }, [web3]);
  const allowClaim = useMemo(() => {
    if (!time) return false;
    if (claimable) return true;
    const now = +new Date();
    return (Number(time) * 1000) < now;
  }, [claimable, time]);
  const onClaim = useCallback(() => {
    if (!web3 || !address || claimed || !allowClaim) return;
    if((!isWhiteListed)){
      toast.error('You are not eligible to claim airdrop!');
      return;
    }
    if (!allocation || Number(allocation) === 0) {
      toast.error('You already claimed airdrop!');
      return;
    }
    (async () => {
      try {
        const contract = new web3.eth.Contract(abi, AppConfig.contract.AIRDROP_CONTRACT);
        await contract.methods.claim().send({
          from: address,
        });
        setNonce(nonce + 1);
      } catch (e) {
        toast.error(e?.message || e.toString());
      }
    })();
  }, [web3, address, allocation, claimed, nonce, allowClaim,isWhiteListed]);
  if(!time){
    return null
  }
  return (
    <div
      className={`px-6 py-4 bg-white shadow-md rounded-lg flex items-center ${claimed ? 'border border-pinkmoon' : 'airdrop cursor-pointer hover:text-pinkmoon-bold'}`}
      onClick={onClaim}
    >
      <img
        src="/feature-2.png"
        alt="pinkm"
        className="w-[32px]"
      />
      {!allowClaim && time? (
        <div className="text-center ml-4">
          <div className="text-sm">Claim PinkS airdrop in</div>
          <Countdown
            date={Number(time) * 1000}
            onComplete={onCountdownComplete}
          />
        </div>
      ) : 
        <>
        {!isWhiteListed?(
            <div className="font-bold underline ml-4">{'You are not eligible to claim PinkS airdrop!'}</div>
        ):(
            <div className="font-bold underline ml-4">{claimed ? 'PinkS airdrop claimed' : 'Claim '+new Intl.NumberFormat('en-IN').format(web3.utils.fromWei(allocation||'0'))+' PinkS!'}</div>
        )}
        </>
      } 
    </div>
  );
};

export default AirdropButton;
