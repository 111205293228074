/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import useWeb3 from "./useWeb3";

function usePoolContract(address: string, abi: string): any {
  const [contract, setContract] = useState<any>();
  const web3 = useWeb3();

  useEffect(() => {
    if (web3) {
      (async () => {
        const c = new web3.eth.Contract(abi, address);
        setContract(c);
      })();
    }
  }, [web3]);

  return contract;
}

export default usePoolContract;
