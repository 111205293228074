import Web3 from 'web3';

// @ts-ignore
const BN = Web3.utils.BN;

export function numberFormat(n: string | number) {
  return String(n).replace(/(.)(?=(\d{3})+$)/g,'$1,');
}

export function fromWei(num: string, decimals = '18') {
  return (
    new BN(num).div(
      new BN(10).pow(new BN(decimals))
    )
  ).toString();
}
