import IPool from "../types/IPool";

const privateSaleAbi = require("../assets/contracts/PrivateSale.json");
const privateSaleV2Abi = require("../assets/contracts/PrivateSaleV2.json");
const privateSaleV3Abi = require("../assets/contracts/PrivateSaleV3.json");

interface IPoolConfig {
  enabled: IPool[];
}

const pool: IPoolConfig = {
  enabled: [
    {
      id: "pinksale",
      name: "PinkSale",
      address: "0xA79258F9924840Eb0E2244D1C418B81967e7Cb3F",
      abi: privateSaleV3Abi,
      icon: "/pinksale.png",
      accessType: "private",
      maximumBnbToBuy: "1",
      tokenSymbol: "PINKSALE",
      links: {
        contract:
          "https://bscscan.com/address/0xA79258F9924840Eb0E2244D1C418B81967e7Cb3F",
        token:
          "https://bscscan.com/token/0x602bA546A7B06e0FC7f58fD27EB6996eCC824689",
      },
      version: 3,
    },
    {
      id: "safehold",
      name: "SafeHold",
      address: "0x403F402fb7811bCe200AB3123Cc97B44601C6FBc",
      abi: privateSaleV2Abi,
      icon: "/safehold.png",
      accessType: "private",
      maximumBnbToBuy: "1",
      tokenSymbol: "SAFEHOLD",
      links: {
        contract:
          "https://bscscan.com/address/0x403F402fb7811bCe200AB3123Cc97B44601C6FBc",
        token:
          "https://bscscan.com/token/0xEce59e58046179A762513727607629641190e421",
      },
      version: 2,
    },
    {
      id: "pinkelon",
      name: "PinkElon",
      address: "0x03BFEE409757BF8caDdffAF494c411186AC5E1B0",
      abi: privateSaleAbi,
      icon: "/pinkelon.png",
      accessType: "private",
      maximumBnbToBuy: "0.25",
      tokenSymbol: "PinkE",
      links: {
        contract:
          "https://bscscan.com/address/0x03BFEE409757BF8caDdffAF494c411186AC5E1B0",
        token:
          "https://bscscan.com/token/0x8DA0F18e4deB7Ba81dBD061DF57325a894014B5a",
      },
      version: 1,
    },
  ],
};

export default pool;
