import { FC } from "react";
import Footer from "./Footer";

import Header from "./Header";

const MainLayout: FC = ({ children }) => {
  return (
    <>
      <Header />
      <div>{children}</div>
      <Footer />
    </>
  );
};

export default MainLayout;
