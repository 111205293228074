function Tiers() {
  return (
    <>
      <div className="px-4 pt-[80px] pb-[120px]" style={{background: '#f9f9f9'}}>
        <div className="container text-center bg-text">
          <h1 className="text-[42px] mb-[32px] intro-title">
            Tier Allocations
          </h1>

          <div className="tier-allocations">
            <div className="allocations clearfix">
              <div className="allocation">
                <div className="allocation-box">
                  <h3>Tier 1</h3>

                  <span className={"guaranteed"}>Guaranteed</span>

                  <img src={"pinkmoon-1.png"} alt={"tier-1"}/>

                  <span className={"holding-avr"}>Holding Avr 7 Days</span>

                  <span className={"holding-number"}>1,000,000,000,000 $PinkM</span>

                  <span className={"pool-weight"}>Pool Weight</span>

                  <span className={"pool-number"}>40%</span>
                </div>
              </div>

              <div className="allocation">
                <div className="allocation-box">
                  <h3>Tier 2</h3>

                  <span className={"guaranteed"}>Guaranteed</span>

                  <img src={"pinkmoon-2.png"} alt={"tier-2"}/>

                  <span className={"holding-avr"}>Holding Avr 7 Days</span>

                  <span className={"holding-number"}>500,000,000,000 $PinkM</span>

                  <span className={"pool-weight"}>Pool Weight</span>

                  <span className={"pool-number"}>20%</span>
                </div>
              </div>

              <div className="allocation">
                <div className="allocation-box">
                  <h3>Tier 3</h3>

                  <span className={"guaranteed"}>Guaranteed</span>

                  <img src={"pinkmoon-6.png"} alt={"tier-3"}/>

                  <span className={"holding-avr"}>Holding Avr 7 Days</span>

                  <span className={"holding-number"}>250,000,000,000 $PinkM</span>

                  <span className={"pool-weight"}>Pool Weight</span>

                  <span className={"pool-number"}>10%</span>
                </div>
              </div>

              <div className="allocation">
                <div className="allocation-box">
                  <h3>Tier 4</h3>

                  <span className={"guaranteed"}>Guaranteed</span>

                  <img src={"pinkmoon-4.png"} alt={"tier-4"}/>

                  <span className={"holding-avr"}>Holding Avr 7 Days</span>

                  <span className={"holding-number"}>125,000,000,000 $PinkM</span>

                  <span className={"pool-weight"}>Pool Weight</span>

                  <span className={"pool-number"}>5%</span>
                </div>
              </div>

              <div className="allocation">
                <div className="allocation-box">
                  <h3>Tier 5</h3>

                  <span className={"guaranteed"}>Guaranteed</span>

                  <img src={"pinkmoon-5.png"} alt={"tier-5"}/>

                  <span className={"holding-avr"}>Holding Avr 7 Days</span>

                  <span className={"holding-number"}>62,500,000,000 $PinkM</span>

                  <span className={"pool-weight"}>Pool Weight</span>

                  <span className={"pool-number"}>2.5%</span>
                </div>
              </div>

              <div className="allocation">
                <div className="allocation-box">
                  <h3>Tier 6</h3>

                  <span className={"guaranteed"}>Lottery</span>

                  <img src={"pinkmoon-3.png"} alt={"tier-3"}/>

                  <span className={"holding-avr"}>Holding Avr 7 Days</span>

                  <span className={"holding-number"}>0 $PinkM</span>

                  <span className={"pool-weight"}>Pool Weight</span>

                  <span className={"pool-number"}>17.5%</span>
                </div>
              </div>
            </div>
          </div>

        </div>
      </div>
    </>
  )
}

export default Tiers
