import { useRouteMatch } from 'react-router'
import { useMount } from 'react-use'
import PoolCard from '../../components/PoolCard'
import AppConfig from '../../configs'
import { FaLink,FaTelegram, FaTwitter, FaMedium, FaGithub } from 'react-icons/fa'
function PoolDetailPage() {
  const { params } = useRouteMatch()
  useMount(() => {
    window.scroll(0, 0)
  })
  // @ts-ignore
  const pool = AppConfig.pool.enabled.find((v) => v.id === params.id ?? '')
  if (!pool) {
    return (
      <div className="px-4 py-[120px] bg-pinkmoon-img bg-center">
        <div className="container">
          <div className="flex justify-center text-center text-4xl font-semibold">
            Pool not found
          </div>
        </div>
      </div>
    )
  }
  if(pool?.id==="pinksale"){
    return (
      <>
        <div className="py-[80px] bg-gray-50 bg-center">
          <div className="container">
            <div className="px-[20px] clearfix">
              <div className="pool-detail-left pr-12">
                <div className="flex items-center">
                  <img
                    src={pool.icon}
                    alt={pool.name}
                    className="w-[64px] h-[64px] mb-[30px]"
                  />
                </div>
  
                <h1 className="flex-1 text-[32px] font-semibold">{pool.name}</h1>
  
                <p className="mt-[30px] text-[18px] ">
                PinkSale is a platform that will allow users to build their own tokens or token launchpads.<br/> Straightforward and user-friendly the terminal is intuitive and simple, allowing for anyone interested to be able to create their own project without any background in coding.<br/>With features such as locking your LP, creating a vesting period, PinkSale will allow you to make robust and complete tokens with interesting tokenomics and features.</p>
                <div className="flex mt-6 bg-center">
                <a
                    href="https://pinksale.finance"
                    className="duration-200 mr-8 text-[24px]"
                    target="_blank"
                    rel="nofollow noreferrer"
                  >
                    <FaLink />
                  </a>
                  <a
                    href="https://t.me/pinkmoonfinance"
                    className="duration-200 mr-8 text-[24px]"
                    target="_blank"
                    rel="nofollow noreferrer"
                  >
                    <FaTelegram />
                  </a>
                  <a
                    href="https://twitter.com/pinkmoonfinance"
                    className="duration-200 mr-8 text-[24px]"
                    target="_blank"
                    rel="nofollow noreferrer"
                  >
                    <FaTwitter />
                  </a>
                  <a
                    href="https://pinkmoonfinance.medium.com/"
                    className="duration-200 mr-8 text-[24px]"
                    target="_blank"
                    rel="nofollow noreferrer"
                  >
                    <FaMedium />
                  </a>
                </div>
                <div className="flex mt-6">
                  <a
                    href={pool.links.contract}
                    className="border-2 border-pinkmoon-bold rounded-lg px-4 py-2 inline-block hover:border-pinkmoon duration-200 bg-white mr-4 font-semibold"
                    target="_blank"
                    rel="nofollow noreferrer"
                  >
                    Presale Contract
                  </a>
                  <a
                    href={pool.links.token}
                    className="border-2 border-pinkmoon-bold rounded-lg px-4 py-2 block hover:border-pinkmoon duration-200 bg-white font-semibold"
                    target="_blank"
                    rel="nofollow noreferrer"
                  >
                    Token Address
                  </a>
                </div>
              </div>
              <div className="rounded-md pool-detail-box">
                <PoolCard {...pool} hideName />
              </div>
            </div>
          </div>
        </div>
        {/* <div className="bg-white container py-[60px] p-[20px]">
          <div className="pool-details mb-[100px]">
            <h2 className="text-2xl">Pool Details</h2>
          </div>
        </div> */}
      </>
    )
  }
  if(pool?.id==="safehold"){
    return (
      <>
        <div className="py-[80px] bg-gray-50 bg-center">
          <div className="container">
            <div className="px-[20px] clearfix">
              <div className="pool-detail-left pr-12">
                <div className="flex items-center">
                  <img
                    src={pool.icon}
                    alt={pool.name}
                    className="w-[64px] h-[64px] mb-[30px]"
                  />
                </div>
  
                <h1 className="flex-1 text-[32px] font-semibold">{pool.name}</h1>
  
                <p className="mt-[30px] text-[18px] ">
                  SafeHold - SafeHold is an autonomous yield farming protocol. Simply hold #SAFEHOLD tokens in your wallet to earn BNB daily. In addition, we burn 2% fee on each transaction so the price will be getting better over time.
                </p>
                <div className="flex mt-6 bg-center">
                <a
                    href="https://safehold.finance"
                    className="duration-200 mr-8 text-[24px]"
                    target="_blank"
                    rel="nofollow noreferrer"
                  >
                    <FaLink />
                  </a>
                  <a
                    href="https://t.me/safeholdfinance"
                    className="duration-200 mr-8 text-[24px]"
                    target="_blank"
                    rel="nofollow noreferrer"
                  >
                    <FaTelegram />
                  </a>
                  <a
                    href="https://twitter.com/safeholdfinance"
                    className="duration-200 mr-8 text-[24px]"
                    target="_blank"
                    rel="nofollow noreferrer"
                  >
                    <FaTwitter />
                  </a>
                  <a
                    href="https://safeholdfinance.medium.com/"
                    className="duration-200 mr-8 text-[24px]"
                    target="_blank"
                    rel="nofollow noreferrer"
                  >
                    <FaMedium />
                  </a>
                </div>
                <div className="flex mt-6">
                  <a
                    href={pool.links.contract}
                    className="border-2 border-pinkmoon-bold rounded-lg px-4 py-2 inline-block hover:border-pinkmoon duration-200 bg-white mr-4 font-semibold"
                    target="_blank"
                    rel="nofollow noreferrer"
                  >
                    Presale Contract
                  </a>
                  <a
                    href={pool.links.token}
                    className="border-2 border-pinkmoon-bold rounded-lg px-4 py-2 block hover:border-pinkmoon duration-200 bg-white font-semibold"
                    target="_blank"
                    rel="nofollow noreferrer"
                  >
                    Token Address
                  </a>
                </div>
              </div>
              <div className="rounded-md pool-detail-box">
                <PoolCard {...pool} hideName />
              </div>
            </div>
          </div>
        </div>
        {/* <div className="bg-white container py-[60px] p-[20px]">
          <div className="pool-details mb-[100px]">
            <h2 className="text-2xl">Pool Details</h2>
          </div>
        </div> */}
      </>
    )
  }
  return (
    <>
      <div className="py-[80px] bg-gray-50 bg-center">
        <div className="container">
          <div className="px-[20px] clearfix">
            <div className="pool-detail-left pr-12">
              <div className="flex items-center">
                <img
                  src={pool.icon}
                  alt={pool.name}
                  className="w-[64px] h-[64px] mb-[30px]"
                />
              </div>

              <h1 className="flex-1 text-[32px] font-semibold">{pool.name}</h1>

              <p className="mt-[30px] text-[18px] ">
                PinkElon - A new level in obtaining value . Auto-lock your lp
                and get access to passive value. Gain more rewards from your
                investments. Join us and let’s take this rocket to the moon.
              </p>
              <div className="flex mt-6 bg-center">
              <a
                  href="https://pinkelon.finance"
                  className="duration-200 mr-8 text-[24px]"
                  target="_blank"
                  rel="nofollow noreferrer"
                >
                  <FaLink />
                </a>
                <a
                  href="https://t.me/pinkelon"
                  className="duration-200 mr-8 text-[24px]"
                  target="_blank"
                  rel="nofollow noreferrer"
                >
                  <FaTelegram />
                </a>
                <a
                  href="https://twitter.com/pinkmoonfinance"
                  className="duration-200 mr-8 text-[24px]"
                  target="_blank"
                  rel="nofollow noreferrer"
                >
                  <FaTwitter />
                </a>
                <a
                  href="https://medium.com/@pinkmoonfinance"
                  className="duration-200 mr-8 text-[24px]"
                  target="_blank"
                  rel="nofollow noreferrer"
                >
                  <FaMedium />
                </a>
                <a
                  href="https://github.com/pinkmoonfinance"
                  className="duration-200 mr-8 text-[24px]"
                  target="_blank"
                  rel="nofollow noreferrer"
                >
                  <FaGithub />
                </a>
              </div>
              <div className="flex mt-6">
                <a
                  href={pool.links.contract}
                  className="border-2 border-pinkmoon-bold rounded-lg px-4 py-2 inline-block hover:border-pinkmoon duration-200 bg-white mr-4 font-semibold"
                  target="_blank"
                  rel="nofollow noreferrer"
                >
                  Presale Contract
                </a>
                <a
                  href={pool.links.token}
                  className="border-2 border-pinkmoon-bold rounded-lg px-4 py-2 block hover:border-pinkmoon duration-200 bg-white font-semibold"
                  target="_blank"
                  rel="nofollow noreferrer"
                >
                  Token Address
                </a>
              </div>
            </div>
            <div className="rounded-md pool-detail-box">
              <PoolCard {...pool} hideName />
            </div>
          </div>
        </div>
      </div>
      {/* <div className="bg-white container py-[60px] p-[20px]">
        <div className="pool-details mb-[100px]">
          <h2 className="text-2xl">Pool Details</h2>
        </div>
      </div> */}
    </>
  )
}

export default PoolDetailPage
